import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.news-carousel-group');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      new Swiper(
        carouselBlock.querySelector('.news-carousel-group__carousel'),
        {
          slidesPerView: 1.1,
          spaceBetween: 10,
          centerInsufficientSlides: true,

          navigation: {
            prevEl: carouselBlock.querySelector('.swiper-button-prev'),
            nextEl: carouselBlock.querySelector('.swiper-button-next'),
          },

          breakpoints: {
            600: {
              slidesPerView: 1.6,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 16,
            },
            1160: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          },
        }
      );
    });
  }
});
